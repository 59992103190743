import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingYear, BalanceSheetsEmailParts, ReviewSummary } from '@dougs/accounting-years/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Job } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountingYearHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccountingYears(companyId: number): Observable<AccountingYear[]> {
    return this.http.get<AccountingYear[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years`,
    );
  }

  getAccountingYear(companyId: number, accountingYearId: number): Observable<AccountingYear> {
    return this.http.get<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}`,
    );
  }

  getActiveAccountingYear(companyId: number): Observable<AccountingYear> {
    return this.http.get<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/active`,
    );
  }

  getCurrentAccountingYear(companyId: number): Observable<AccountingYear> {
    return this.http.get<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/current`,
    );
  }

  getBalanceSheetsEmailParts(accountingYear: AccountingYear): Observable<BalanceSheetsEmailParts> {
    return this.http.get<BalanceSheetsEmailParts>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/actions/get-balance-sheets-email-parts`,
    );
  }

  getDiaClosingFiles(accountingYear: AccountingYear): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/dia-closing-files`,
    );
  }

  updateAccountingYear(accountingYear: Partial<AccountingYear>): Observable<AccountingYear> {
    return this.http.post<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}`,
      accountingYear,
    );
  }

  generatePreviousAccountingYearFromFec(accountingYear: AccountingYear): Observable<Job> {
    return this.http.post<Job>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/fec/generate-previous-accounting-year`,
      {},
    );
  }

  generateReversalsFromFec(accountingYear: AccountingYear): Observable<Job> {
    return this.http.post<Job>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/fec/generate-reversals`,
      {},
    );
  }

  requestModification(
    accountingYear: AccountingYear,
    reason: {
      invoiceCustomer: boolean;
      message: string;
      customInvoice: boolean;
      customInvoiceAmount: number;
    },
  ): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/actions/request-modification`,
      reason,
    );
  }

  sendPreviousAccountantEmail(accountingYear: AccountingYear): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/actions/send-previous-accountant-email`,
      {},
    );
  }

  getReviewSummary(accountingYear: AccountingYear): Observable<ReviewSummary> {
    return this.http.get<ReviewSummary>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/review-summary`,
    );
  }

  completeRevision(companyId: number, accountingYearId: number): Observable<AccountingYear> {
    return this.http.post<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/complete-revision`,
      {},
    );
  }

  sendRemoteDeclaration2065Test(companyId: number, accountingYearId: number): Observable<AccountingYear> {
    return this.http.get<AccountingYear>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/test-declaration-2065`,
    );
  }
}
