import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  constructor(private readonly http: HttpClient) {}

  deleteAccountingYearFile(accountingYear: AccountingYear, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/attachments/${attachment.id}`,
    );
  }

  uploadAccountingYearFile(
    accountingYear: AccountingYear,
    fileType: string | undefined,
    file: File,
  ): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }

  updateAccountingYearFile(attachment: Attachment): Observable<Attachment> {
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${attachment.companyId}/accounting-years/${attachment.modelId}/attachments/${attachment.id}`,
      attachment,
    );
  }
}
