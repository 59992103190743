import { Injectable } from '@angular/core';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  addMultipleAttachmentAccountingYearFromKey(
    accountingYear: AccountingYear,
    attachments: Attachment[],
    modelFileKey: keyof AccountingYear,
  ): AccountingYear {
    return {
      ...accountingYear,
      [modelFileKey]: accountingYear[modelFileKey]
        ? [...(accountingYear[modelFileKey] as Attachment[]), ...attachments]
        : [...attachments],
    };
  }

  removeMultipleAttachmentAccountingYearFromKey(
    accountingYear: AccountingYear,
    attachment: Attachment,
    modelFileKey: keyof AccountingYear,
  ): AccountingYear {
    const attachments = (accountingYear[modelFileKey] as Attachment[])?.filter(
      (attachmentIterated: Attachment) => attachmentIterated.id !== attachment.id,
    );
    return {
      ...accountingYear,
      [modelFileKey]: attachments?.length > 0 ? attachments : null,
    };
  }

  addSingleAttachmentAccountingYearFromKey(
    accountingYear: AccountingYear,
    attachment: Attachment,
    modelFileKey: keyof AccountingYear,
  ): AccountingYear {
    return {
      ...accountingYear,
      [modelFileKey]: attachment,
    };
  }

  removeSingleAttachmentAccountingYearFromKey(
    accountingYear: AccountingYear,
    modelFileKey: keyof AccountingYear,
  ): AccountingYear {
    return {
      ...accountingYear,
      [modelFileKey]: null,
    };
  }
}
