import { Injectable } from '@angular/core';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class AccountingYearService {
  getMostRecentSignedBalanceSheet(accountingYear: AccountingYear): Attachment | undefined {
    const filteredDocuments = accountingYear.accountingYearBalanceSheets?.filter(
      (attachment) => !attachment.fileId || !attachment.metadata?.balanceSheetByDougs || attachment.metadata.isSigned,
    );

    if (!filteredDocuments?.length) {
      return undefined;
    }

    const uploadingBalanceSheet = filteredDocuments.find((attachment) => !attachment.fileId);

    if (uploadingBalanceSheet) {
      return uploadingBalanceSheet;
    }

    return filteredDocuments.sort(
      (a, b) => new Date(b.file.updatedAt || '').getTime() - new Date(a.file.updatedAt || '').getTime(),
    )[0];
  }
}
