import { Injectable } from '@angular/core';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AccountingYear,
  AccountingYearAttachment,
  BalanceSheetsEmailParts,
  ReviewSummary,
} from '@dougs/accounting-years/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { JobService } from '@dougs/core/job';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingYearHttpService } from '../http/accounting-year.http';
import { FileHttpService } from '../http/file.http';
import { AttachmentService } from '../services/attachment.service';

interface AccountingYearState {
  accountingYears: AccountingYear[];
  activeAccountingYear: AccountingYear;
  editedAccountingYear: AccountingYear | null;
  diaClosingFiles: Attachment[];
}

@Injectable({
  providedIn: 'root',
})
export class AccountingYearStateService extends StateService<AccountingYearState> {
  readonly accountingYears$: Observable<AccountingYear[]> = this.select((state) => state.accountingYears);
  readonly accountingYearsEffectiveClosingDate$: Observable<AccountingYear[]> = this.select((state) =>
    [...state.accountingYears]?.sort(
      (a, b) => new Date(b.effectiveClosingDate).getTime() - new Date(a.effectiveClosingDate).getTime(),
    ),
  );
  readonly activeAccountingYear$: Observable<AccountingYear> = this.select((state) => state.activeAccountingYear);
  readonly editedAccountingYear$: Observable<AccountingYear | null> = this.select(
    (state) => state.editedAccountingYear,
  );
  readonly diaBackupFile$: Observable<Attachment | undefined> = this.select((state) =>
    state.diaClosingFiles.find((file) => file.type === 'diaBackup'),
  );
  readonly diaTirageFile$: Observable<Attachment | undefined> = this.select((state) =>
    state.diaClosingFiles.find((file) => file.type === 'diaTirage'),
  );
  readonly diaFecFile$: Observable<Attachment | undefined> = this.select((state) =>
    state.diaClosingFiles.find((file) => file.type === 'diaFec'),
  );
  readonly diaJournalFile$: Observable<Attachment | undefined> = this.select((state) =>
    state.diaClosingFiles.find((file) => file.type === 'diaJournal'),
  );
  readonly currentAccountingYear$: Observable<AccountingYear | undefined> = combineLatest([
    this.companyStateService.activeCompany$,
    this.accountingYears$,
  ]).pipe(
    map(([company, accountingYears]) =>
      accountingYears?.find((accountingYear) => company.accountingYear?.id === accountingYear.id),
    ),
  );

  constructor(
    private readonly logger: LoggerService,
    private readonly accountingYearHttpService: AccountingYearHttpService,
    private readonly fileHttpService: FileHttpService,
    private readonly attachmentService: AttachmentService,
    private readonly jobService: JobService,
    private readonly companyStateService: CompanyStateService,
  ) {
    super();
  }

  async refreshAccountingYears(companyId: number): Promise<AccountingYear[]> {
    try {
      const accountingYears: AccountingYear[] = this.getAndSetCacheState('accounting-years', companyId)
        ? this.state.accountingYears
        : await lastValueFrom(this.accountingYearHttpService.getAccountingYears(companyId));

      this.setState({
        accountingYears,
      });

      return accountingYears;
    } catch (e) {
      this.clearCache('accounting-years');
      this.logger.error(e);
      return [];
    }
  }

  async getAccountingYear(companyId: number, accountingYearId: number): Promise<AccountingYear | null> {
    try {
      const accountingYear: AccountingYear = await lastValueFrom(
        this.accountingYearHttpService.getAccountingYear(companyId, accountingYearId),
      );

      this.setState({
        accountingYears:
          this.state?.accountingYears?.map((accountingYearIterated) =>
            accountingYearIterated.id === accountingYear.id ? accountingYear : accountingYearIterated,
          ) || [],
      });
      return accountingYear;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getCurrentAccountingYear(companyId: number): Promise<AccountingYear | null> {
    try {
      const accountingYear: AccountingYear = await lastValueFrom(
        this.accountingYearHttpService.getCurrentAccountingYear(companyId),
      );

      this.setState({
        accountingYears:
          this.state?.accountingYears?.map((accountingYearIterated) =>
            accountingYearIterated.id === accountingYear.id ? accountingYear : accountingYearIterated,
          ) || [],
      });
      return accountingYear;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getBalanceSheetsEmailPartsFromAccountingYear(
    accountingYear: AccountingYear,
  ): Promise<BalanceSheetsEmailParts | null> {
    try {
      return await lastValueFrom(this.accountingYearHttpService.getBalanceSheetsEmailParts(accountingYear));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateAccountingYear(accountingYear: Partial<AccountingYear>): Promise<boolean> {
    try {
      const accountingYearUpdated: AccountingYear = await lastValueFrom(
        this.accountingYearHttpService.updateAccountingYear(accountingYear),
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated) =>
          accountingYearIterated.id === accountingYearUpdated.id ? accountingYearUpdated : accountingYearIterated,
        ),
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async refreshActiveAccountingYear(companyId: number): Promise<AccountingYear | null> {
    try {
      const activeAccountingYear: AccountingYear = this.getAndSetCacheState('activeAccountingYear', companyId)
        ? this.state.activeAccountingYear
        : await lastValueFrom(this.accountingYearHttpService.getActiveAccountingYear(companyId));
      this.setState({
        activeAccountingYear,
      });
      return activeAccountingYear;
    } catch (e) {
      this.clearCache('activeAccountingYear');
      this.logger.error(e);
      return null;
    }
  }

  async refreshDiaClosingFiles(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        diaClosingFiles: await lastValueFrom(this.accountingYearHttpService.getDiaClosingFiles(accountingYear)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachment(
    accountingYear: AccountingYear,
    file: File,
    accountingYearModelKey: keyof AccountingYear,
    type: string,
  ): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(
        this.fileHttpService.uploadAccountingYearFile(accountingYear, type, file),
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated: AccountingYear) =>
          accountingYearIterated.id === accountingYear.id
            ? this.attachmentService.addSingleAttachmentAccountingYearFromKey(
                accountingYear,
                attachment,
                accountingYearModelKey,
              )
            : accountingYearIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteAttachment(
    accountingYear: AccountingYear,
    attachment: Attachment,
    accountingYearModelKey: keyof AccountingYear,
  ): Promise<AccountingYear | null> {
    try {
      await lastValueFrom(this.fileHttpService.deleteAccountingYearFile(accountingYear, attachment));

      const updatedAccountingYear: AccountingYear = this.attachmentService.removeSingleAttachmentAccountingYearFromKey(
        accountingYear,
        accountingYearModelKey,
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated: AccountingYear) =>
          accountingYearIterated.id === accountingYear.id ? updatedAccountingYear : accountingYearIterated,
        ),
      });

      return updatedAccountingYear;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async deleteMultipleAttachment(
    accountingYear: AccountingYear,
    attachment: Attachment,
    accountingYearModelKey: keyof AccountingYear,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteAccountingYearFile(accountingYear, attachment));

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated: AccountingYear) =>
          accountingYearIterated.id === accountingYear.id
            ? this.attachmentService.removeMultipleAttachmentAccountingYearFromKey(
                accountingYear,
                attachment,
                accountingYearModelKey,
              )
            : accountingYearIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachments(
    accountingYear: AccountingYear,
    files: FileList,
    accountingYearModelKey: keyof AccountingYear,
    type: string,
  ): Promise<AccountingYear | null> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.fileHttpService.uploadAccountingYearFile(accountingYear, type, file)),
        ),
      );

      const updatedAccountingYear: AccountingYear = this.attachmentService.addMultipleAttachmentAccountingYearFromKey(
        accountingYear,
        attachments,
        accountingYearModelKey,
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated) =>
          accountingYearIterated.id === accountingYear.id ? updatedAccountingYear : accountingYearIterated,
        ),
      });

      return updatedAccountingYear;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async updateAttachment(attachment: AccountingYearAttachment, modelKey: keyof AccountingYear): Promise<boolean> {
    try {
      const updatedFile: Attachment = await lastValueFrom(this.fileHttpService.updateAccountingYearFile(attachment));

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYear: AccountingYear) =>
          accountingYear.id === updatedFile.modelId
            ? this.attachmentService.addSingleAttachmentAccountingYearFromKey(accountingYear, updatedFile, modelKey)
            : accountingYear,
        ),
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async sendPreviousAccountantEmail(accountingYear: AccountingYear): Promise<boolean> {
    try {
      await lastValueFrom(this.accountingYearHttpService.sendPreviousAccountantEmail(accountingYear));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async requestModification(
    accountingYear: AccountingYear,
    reason: {
      invoiceCustomer: boolean;
      message: string;
      customInvoice: boolean;
      customInvoiceAmount: number;
    },
  ): Promise<boolean> {
    try {
      await lastValueFrom(this.accountingYearHttpService.requestModification(accountingYear, reason));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async generatePreviousAccountingYearFromFec(accountingYear: AccountingYear): Promise<boolean> {
    try {
      const job: Job = await lastValueFrom(
        this.accountingYearHttpService.generatePreviousAccountingYearFromFec(accountingYear),
      );
      await lastValueFrom(this.jobService.handleJob(job));
      await this.getAccountingYear(accountingYear.companyId, accountingYear.id);

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async generateReversalsFromFec(accountingYear: AccountingYear): Promise<void> {
    try {
      const job: Job = await lastValueFrom(this.accountingYearHttpService.generateReversalsFromFec(accountingYear));
      await lastValueFrom(this.jobService.handleJob(job));
      await this.getAccountingYear(accountingYear.companyId, accountingYear.id);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateReviewSummary(accountingYear: AccountingYear): Promise<void> {
    try {
      const reviewSummary: ReviewSummary = await lastValueFrom(
        this.accountingYearHttpService.getReviewSummary(accountingYear),
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated) =>
          accountingYearIterated.id === accountingYear.id
            ? {
                ...accountingYear,
                metadata: {
                  ...accountingYear.metadata,
                  reviewSummary,
                },
              }
            : accountingYearIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async completeRevision(accountingYear: AccountingYear): Promise<boolean> {
    try {
      const updatedAccountingYear: AccountingYear = await lastValueFrom(
        this.accountingYearHttpService.completeRevision(accountingYear.companyId, accountingYear.id),
      );

      this.setState({
        accountingYears: this.state.accountingYears.map((accountingYearIterated) =>
          accountingYearIterated.id === accountingYear.id
            ? {
                ...updatedAccountingYear,
                metadata: {
                  ...updatedAccountingYear.metadata,
                  reviewSummary: accountingYear.metadata.reviewSummary,
                },
              }
            : accountingYearIterated,
        ),
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  selectEditedAccountingYear(accountingYear: AccountingYear | null): void {
    this.setState({
      editedAccountingYear: accountingYear,
    });
  }

  async sendRemoteDeclaration2065Test(accountingYear: AccountingYear): Promise<void> {
    try {
      await lastValueFrom(
        this.accountingYearHttpService.sendRemoteDeclaration2065Test(accountingYear.companyId, accountingYear.id),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }
}
